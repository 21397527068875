<template>
	<div>
		<v-container>
			<div>
				<v-layout mb-5>
					<v-btn
						icon
						@click="$router.go(-1)"
						class="mr-2">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
					<h1 v-if="test"
						class="headline font-weight-black">{{test.name}}</h1>
					<v-skeleton-loader
						v-else
						type="heading"
						width="100%"></v-skeleton-loader>
				</v-layout>
				<v-form
					v-if="test"
					name="myform"
					class="swiper-container"
					ref="swiper"
					@submit.prevent="submit">
					<div class="swiper-wrapper">
						<v-card v-for="(question, key) in test.questions"
							:key="question.id"
							class="swiper-slide"
							elevation="0">
							<v-container>
								<h3 class="title question-title">{{ key + 1 }}. {{ question.text }}</h3>
								<v-img
									v-for="(image, key) in question.images"
									:key="key"
									:src="image.original_url"
									contain
									max-width="500"
									max-height="250">
								</v-img>
								<question-paired
									v-if="question.type === 1"
									:question="question"
									@input="updateQuestion"/>
								<question-singled
									v-else-if="question.type === 2"
									:question="question"
									@input="updateQuestion"/>
								<question-tile
									v-else
									:question="question"
									@input="updateQuestion"/>
							</v-container>
						</v-card>
					</div>
				</v-form>
				<v-skeleton-loader
					v-else
					type="card-heading, list-item-two-line@4"></v-skeleton-loader>
				<v-alert
					v-if="testid === adTestId"
					type="success"
					:icon="false"
					dense
					text>
					<v-row
						class="align-center ma-0">
						<v-col
							cols="auto"
							class="py-0">
							Цікавить курс англійської до ЄВІ (ЗНО магістратура)?
						</v-col>
						<v-col
							cols="auto"
							class="py-0">
							<v-btn
								href="https://magistracy.brames.com.ua"
								target="blank"
								color="amber"
								text>
								Реєструйся
							</v-btn>
						</v-col>
					</v-row>
				
				</v-alert>
			</div>
		</v-container>
	
		<v-app-bar v-if="test" app bottom class="test-nav blured-sheet" elevation="0" extension-height="60">
			<v-progress-linear
				:value="progress"
				height="4"
				color="secondary">
			</v-progress-linear>

			<v-layout align-center ml-2 mr-2>
				<v-btn
					text
					color="primary"
					@click.prevent.stop="swiper.slidePrev()">
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>

				<v-container pa-1 pl-2 pr-2 class="d-flex justify-space-between align-center text-truncate">
					<h2 class="subtitle-1 text-truncate">{{ test.test_name }}</h2>

					<v-bottom-sheet v-model="bottomSheet" scrollable overlay-color="black">
						<template v-slot:activator="{ on }">
							<v-btn
								text
								v-on="on"
								class="pa-0"
								color="success">
								<small class="font-weight-black font-italic">
									{{ activeIndex + 1 }}/{{ test.questions.length }}
								</small>
								<v-icon>mdi-chevron-up</v-icon>
							</v-btn>
						</template>
						<v-card class="blured-sheet bottom-sheet">
							<v-card-title>
								Тест "{{ test.test_name }}"
							</v-card-title>
							<v-divider></v-divider>
							<v-card-text style="max-height: 75vh;">
								<v-row>
									<v-col xs6>Попередній результат:</v-col>
								</v-row>
								<v-divider></v-divider>
								<v-layout wrap justify-center>
									<v-btn
										v-for="(question, key) in test.questions"
										:key="question.id"
										elevation="0"
										class="ma-2"
										:color="question.show_result ? (question.result ? 'success' : 'error') : ''"
										@click="swiper.slideTo(key),  bottomSheet = !bottomSheet">
										{{ key + 1 }}
									</v-btn>
								</v-layout>
							</v-card-text>
						</v-card>
					</v-bottom-sheet>

				</v-container>
						
				<v-btn
					text
					color="primary"
					@click.prevent.stop="swiper.slideNext()">
					<v-icon>mdi-chevron-right</v-icon>
				</v-btn>
			</v-layout>

			<template slot="extension">
				<v-btn
					v-if="answeredQuestions.length === test.questions.length"
					@click="saveResult"
					elevation="0"
					width="80%"
					large
					class="ma-auto"
					color="secondary">
					Зберегти результат 👉
				</v-btn>
				<v-btn
					v-else-if="activeQuestion.show_result"
					elevation="0"
					width="80%"
					large
					text
					class="ma-auto heading font-weight-black font-italic"
					:color="activeQuestion.result ? 'success' : 'error'">
					{{ activeQuestion.result ? 'Правильно' : 'Неправильно' }}
				</v-btn>
				<v-btn
					v-else
					@click="checkQuestion"
					elevation="0"
					width="80%"
					large
					class="ma-auto"
					color="primary">Перевірити питання</v-btn>
			</template>
		</v-app-bar>

		<v-dialog
			v-model="resultDialog"
			persistent
			width="350">
			<ad-result-card
				v-if="result && testid === adTestId"
				:result="result">
			</ad-result-card>
			<v-card
				v-else-if="result"
				color="success"
				dark>
				<v-card-title class="justify-center">
					Результат збережено 🎉
				</v-card-title>
				<v-card-text class="subtitle-1 text-center">
					<p>Твій результат успішності тесту</p>
					<p class="display-2 font-weight-black font-italic">{{ result ? result.result : '' }}%</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						@click="resultDialog = false">
						Закрити
					</v-btn>
					<v-btn
						outlined
						@click="$router.go(-1)"
						exact>
						До тестів
					</v-btn>
				</v-card-actions>
			</v-card>
			<v-card
				v-else
				color="primary"
				dark>
				<v-card-title>
					Зберігаємо результат 🙏
				</v-card-title>
				<v-card-text>
					Будь ласка, почекай
					<v-progress-linear
						indeterminate
						color="white"
						class="mb-0"></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Swiper from 'swiper'
import 'swiper/swiper-bundle.css'
import QuestionTile from '../components/QuestionTile.vue'
import QuestionPaired from '../components/Question/QuestionPaired.vue'
import QuestionSingled from '../components/Question/QuestionSingled.vue'
import AdResultCard from '../components/AdResultCard.vue'

export default {
	components: {
		QuestionTile,
		QuestionPaired,
		QuestionSingled,
		AdResultCard,
	},
	props: {
		testid: {
			type: Number,
			required: true,
		},
	},
	data: () => ({
		swiper: null,
		swiperOptions: {
			slidesPerView: 1,
			spaceBetween: 30,
		},
		bottomSheet: false,
		resultDialog: false,
		adTestId: 294,
	}),
	computed: {
		...mapGetters('test', [
			'test',
			'activeIndex',
			'activeQuestion',
			'answeredQuestions',
			'result',
		]),
		progress() {
			if (this.test)
				return this.answeredQuestions.length / this.test.questions.length * 100
			return null
		},
	},
	methods: {
		...mapActions('test', [
			'loadTest',
			'clearTest',
			'changeQuestion',
			'updateQuestion',
			'checkQuestion',
			'saveResult',
		]),
		initSwiper(options) {
			this.swiper = new Swiper(this.$refs.swiper.$el, options)
		},
		async finishTest() {
			this.resultDialog = true
			await this.saveResult()
		},
	},
	watch: {
		progress(val) {
			if (val === 100) this.finishTest()
		},
	},
	async mounted() {
		if (this.test && !this.result && this.answeredQuestions.length > 0) {
			if (this.test.id !== this.testid) {
				const confirm = await this.$swal('confirm', {
					title: 'Продовжити незавершений тест?',
					content: `Хочеш продовжити незавершений тест "${this.test.name}"? Якщо не продовжити проходити цей тест, то всі незбережені результати в ньому буде стерто.`,
					icon: '🤔',
					buttonCancel: {
						text: 'Ні',
					},
					buttonOk: {
						text: `Так, продовжити "${this.test.name}"`,
					},
				})

				if (confirm) {
					await this.$router.replace({
						name: 'test',
						params: {
							testid: this.test.id,
						},
					})
				} else {
					await this.loadTest(this.testid)
				}
			}
		} else {
			await this.loadTest(this.testid)
		}
		await this.initSwiper(this.swiperOptions)
		this.swiper.on('slideChange', () => {
			this.changeQuestion(this.swiper.activeIndex)
		})
		this.swiper.slideTo(this.activeIndex || 0)
	},
	// beforeDestroy() {
	// 	this.clearTest()
	// },
}
</script>

<style>
.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-wrapper {
	box-sizing: border-box;
}

ol.swiper-wrapper {
	list-style-position: inside;
	padding-left: 0;
}

.test-nav {
	top: initial !important;
	bottom: 0 !important;
}

.test-nav .v-toolbar__content,
.test-nav .v-toolbar__extension {
	padding: 0;
}

.test-nav .v-toolbar__content {
	flex-direction: column;
	align-items: stretch;
}

.question-title {
	white-space: pre-wrap;
}
</style>